import { CartState, rollingTrialKeys } from '@naturalcycles/shared'

/**
 *
 * True if
 * - Cart contains paid items
 * - Cart has rolling trial as we should always take payment info for next renewal subscription.
 * - Cart has recurring subscription, for the same reason as above.
 *
 * Otherwise returns false.
 */
export function requiresPayment(cart: CartState): boolean {
  return (
    (cart.items.length > 0 && cart.totalPrice.amount !== 0) ||
    cart.items.some(i => rollingTrialKeys.includes(i.key) || i.recurring)
  )
}
